import getAssetUrl from '../../../utils/getAssetUrl';
import './styles.scss';

const Demo = () => (
  <div className="demo">
    <video className="demo__video" src={getAssetUrl('hero.mp4')} autoPlay loop muted playsInline />
    <img className="demo__logo" src={getAssetUrl('logo.png')} alt="logo" />
    <div className="demo__container">
      <p>El mundo te espera, y pronto podrás explorarlo con nosotros</p>
      <a href="https://www.instagram.com/gestinatravel/" target="_blank" rel="noopener noreferrer">
        <img className="demo__instagram" src={getAssetUrl('instagram.svg')} alt="logoinsta" />
      </a>
      <p>
        Estamos preparando algo espectacular para tus próximas vacaciones. Pronto podrás descubrir destinos increíbles,
        ofertas exclusivas y mucho mas
      </p>
    </div>
  </div>
);

export default Demo;
