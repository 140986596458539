import ReactDOM from 'react-dom/client';
import Demo from './components/atoms/Demo/index.tsx';
// import App from './App.tsx';
import './global.scss';
import { PageStateProvider } from './utils/pageState.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <PageStateProvider>
    <Demo />
    {/* <App /> */}
  </PageStateProvider>,
);
