import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { User } from './types';

type PageStateContext = {
  user?: User;
};
const PageStateContext = createContext({} as any);

export const PageStateProvider = (props: PropsWithChildren<any>) => {
  const [user, _setUser] = useState<User>();

  return <PageStateContext.Provider value={{ user }}>{props.children}</PageStateContext.Provider>;
};

export const usePageState = () => useContext(PageStateContext);
